import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sep-bonus',
  templateUrl: './sep-bonus.component.html',
})
export class SepBonusComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
